import React from "react"
import useWhitelabel from "hooks/useWhitelabel"

export default function Copyright() {
  const whitelabel = useWhitelabel()

  if (!whitelabel.serviceProvidedBy) {
    return null
  }

  return (
    <p className="mt-9 text-center text-xs text-gray-900">
      {whitelabel.serviceProvidedBy === "dnsfilter" ? (
        <>
          &copy; {new Date().getFullYear()} DNSFilter, Inc. All rights reserved.
        </>
      ) : (
        `Service provided by ${whitelabel.serviceProvidedBy}`
      )}
    </p>
  )
}
