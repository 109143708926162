import React from "react"
import axios from "axios"
import { useRouter } from "next/router"
import { useQuery } from "react-query"
import jwtDecode from "jwt-decode"
import { differenceInMinutes } from "date-fns"
import { webAuth } from "singletons/auth0"
import Layout from "components/Layout"
import Heading from "components/Heading"
import Flex from "components/Flex"
import Container from "components/Container"
import Spacer from "components/Spacer"
import Card from "components/Card"
import Button from "components/Button"
import Logo from "components/Logo"
import Copyright from "components/Copyright"
import sendRequest from "utils/sendRequest"

const isOIDCLogin = () => {
  const token = localStorage.getItem("access_token")
  if (!token) {
    return false
  }
  try {
    return jwtDecode(token).sub.startsWith("oidc|")
  } catch {
    return false
  }
}

export function refreshToken() {
  const access_token = localStorage.getItem("access_token")
  // User is not logged in
  if (!access_token) {
    return Promise.reject("Not logged in")
  }
  const { exp } = jwtDecode(access_token)
  const minutesLeft = differenceInMinutes(Number(exp) * 1000, new Date())
  if (minutesLeft > 1) {
    return Promise.resolve(access_token)
  }
  if (isOIDCLogin()) {
    return new Promise((resolve, reject) => {
      webAuth.checkSession({}, (error, result) => {
        if (error) {
          console.error("[SSO] Failed to renew session", error)
          return reject(new Error(error.error_description))
        }
        const accessToken = result.idToken ?? result.accessToken
        localStorage.setItem("access_token", accessToken)
        resolve(accessToken)
      })
    })
  }
  const refresh_token = localStorage.getItem("refresh_token")
  if (!refresh_token) {
    return Promise.reject("Invalid refresh token")
  }
  return axios({
    method: "post",
    url: `https://${process.env.NEXT_PUBLIC_AUTH0_DOMAIN}/oauth/token`,
    data: {
      client_id: `${process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}`,
      grant_type: "refresh_token",
      access_token,
      refresh_token,
    },
  }).then(({ data }) => {
    const { access_token, refresh_token } = data
    localStorage.setItem("access_token", access_token)
    localStorage.setItem("refresh_token", refresh_token)
    return access_token
  })
}

export default function SignInPage() {
  const router = useRouter()
  const generatedId = router.query.generatedId

  React.useEffect(() => {
    localStorage.removeItem("loginId")
  }, [])

  const connectionsQuery = useQuery(
    ["connections", generatedId],
    () =>
      sendRequest({
        method: "get",
        path: "/auth0/connections?key=" + generatedId,
      }).then((res) => res.data),
    {
      enabled: !!generatedId,
      onSuccess: (data) => {
        if (data.length === 0) {
          router.push("/login")
        }
      },
    },
  )

  const [redirectingTo, setRedirectingTo] = React.useState(null)

  React.useEffect(() => {
    const iframe = window.parent !== window

    if (iframe && localStorage.getItem("distributor")) {
      window.location.href = `/?distplatform=${localStorage.getItem(
        "distributor",
      )}`
    }
  }, [])

  React.useEffect(() => {
    sendRequest({
      method: "post",
      path: "/authenticate?limit_organizations=1&skip_user_checks=false",
    })
      .then(() => (window.location.href = "/"))
      .catch(async () => {
        try {
          await refreshToken()
          window.location.href = "/"
        } catch {}
      })
  }, [])

  return (
    <Layout title="Sign In">
      <Flex value="flex-1 flex flex-col justify-center items-center">
        <h1>
          <Logo />
        </h1>
        <Spacer size="h-8" />
        <Container size="xs">
          <Card>
            <div>
              <Heading align="center">Sign in to your account</Heading>
              <Spacer size="h-8" />
              {connectionsQuery.status === "idle" ||
              connectionsQuery.status === "loading" ||
              (connectionsQuery.status === "success" &&
                connectionsQuery.data.length === 0) ? (
                <Button variant="primary" size="md" loading>
                  Loading
                </Button>
              ) : (
                <div className="space-y-4">
                  {connectionsQuery.data.map((connection) => (
                    <Button
                      key={connection.name}
                      variant="primary"
                      size="md"
                      testId={`${connection.name}-button`}
                      loading={redirectingTo === connection.name}
                      disabled={
                        redirectingTo && redirectingTo !== connection.name
                      }
                      onClick={() => {
                        localStorage.setItem("loginId", generatedId)

                        setRedirectingTo(connection.name)

                        webAuth.authorize({
                          connection: connection.name,
                        })
                      }}
                    >
                      {connection.display_name}
                    </Button>
                  ))}
                </div>
              )}
            </div>
          </Card>
        </Container>
        <Copyright />
      </Flex>
    </Layout>
  )
}
